/** @format */

/** styles  */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles';

/** translations */
import 'assets/locale';

import React from 'react';
import Routes from 'routes';
import ReactDOM from 'react-dom';
import awsConfig from 'config/aws';
import { Amplify } from 'aws-amplify';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { history } from 'stateContainer/history';
import Container from 'react-bootstrap/Container';
import ErrorBoundary from 'components/ErrorBoundary';
import { store, persistor } from 'stateContainer/store';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

Amplify.configure(awsConfig);

ReactDOM.render(
  <ErrorBoundary>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <ConnectedRouter history={history} noInitialPop>
          <Container fluid>
            <Routes />
          </Container>
        </ConnectedRouter>
      </Provider>
    </PersistGate>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
